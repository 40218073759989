import React from 'react'
import { Box, Text, Flex } from '@primer/components'

import PageSetup from '../components/layout/PageSetup'
import PrimaryButton from '../components/reusable/PrimaryButton'
import BackPageButton from '../components/reusable/BackPageButton'

const PaletteBlock = ({ name, col, num, ...rest }) => (
  <Flex alignItems="center" {...rest}>
    <Text fontSize="3" mr="3" fontFamily="mono">
      {name}
    </Text>
    <Box width="300px" height="75px" bg={col} />
    {num && (
      <Text textAlign="center" ml="3" fontFamily="mono">
        {num}
      </Text>
    )}
  </Flex>
)

const style = () => {
  return (
    <PageSetup pageTitle="Style guide">
      <Flex my={8}>
        <BackPageButton text="music" to="/music" />
      </Flex>

      <Flex my={8}>
        <PrimaryButton url="/style">View</PrimaryButton>
      </Flex>

      <Flex my={8}>
        <PaletteBlock name="Primary" col="primary" mr={9} />
        <PaletteBlock name="Secondary" col="secondary" />
      </Flex>

      <Flex flexDirection="column" mb={8}>
        <PaletteBlock name="Dark" col="black" />
        <PaletteBlock name="Light" col="white" />
      </Flex>

      <Flex flexDirection="column" justifyContent="end" mb={8}>
        <PaletteBlock name="Accent1" col="accent.0" num="0" />
        <PaletteBlock name="Accent2" col="accent.1" num="1" />
        <PaletteBlock name="Accent3" col="accent.2" num="2" />
        <PaletteBlock name="Accent4" col="accent.3" num="3" />
        <PaletteBlock name="Accent5" col="accent.4" num="4" />
        <PaletteBlock name="Accent6" col="accent.5" num="5" />
        <PaletteBlock name="Accent7" col="accent.6" num="6" />
        <PaletteBlock name="Accent8" col="accent.7" num="7" />
      </Flex>
    </PageSetup>
  )
}

export default style
