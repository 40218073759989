import React from 'react'
import { Link } from 'gatsby'
import { themeGet, Box, Text } from '@primer/components'
import styled from 'styled-components'

const CustomButton = styled(Box)`
  appearance: none;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  text-align: center;
  text-decoration: none;
  line-height: 38px;
  white-space: nowrap;
  min-width: 200px;
  height: 40px;
  padding: 0 25px 0 25px;
  border-radius: ${themeGet('radii.2')};
  font-size: 0.875rem;
  flex-shrink: 0;
  color: white;
  background-color: ${themeGet('colors.bodytext')};
  border: 1px solid ${themeGet('colors.bodytext')};
  transition: all 200ms ease;
  user-select: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;

  :hover {
    color: ${themeGet('colors.bodytext')};
    border: 1px solid ${themeGet('colors.bodytext')};
    background: ${themeGet('colors.light')};
  }
`

const PrimaryButton = ({ children, url, ...rest }) => {
  return (
    <CustomButton as={Link} role="button" to={url} {...rest}>
      <Text>{children}</Text>
    </CustomButton>
  )
}

export default PrimaryButton
